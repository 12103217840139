import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

class Head extends Component {
  constructor(props) {
    super(props);
  }
  //! manter aqui
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  //! manter aqui
  componentDidMount() {
  }

  render() {
    var childProps = this.props.childProps;
    var central = childProps.empresaData;
    let parceriaicon = central.parceriaicon;
    return (
      <div>
        <div style={{
                          backgroundImage: `url(${parceriaicon})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: 90,
                          height: 90,
                        }} />
        <div>
          Parceria: {central.razaosocial}<br/>
          Valor:{" "} <CurrencyFormat 
              decimalScale={2} 
              fixedDecimalScale={true}
              value={Number(central.parceriavalor)} 
              displayType={'text'} 
              decimalSeparator={','} 
              thousandSeparator={'.'} 
              prefix={"R$"} /><br/>
          Código da parceria: {central.parceria}<br/>

        </div>
      </div>
    );
  }
}
export default Head;
