import { Component } from "react";
import SubHeader from "../header/SubHeaderView";
import List from "./view/List";
import Head from "./view/Head";
import ViewModel from "./controllers/ViewModel";

export default class Parceria extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new ViewModel();
    this.viewModel.mirrorState = (s) => this.setState(s);
    this.viewModel.props = props;
  }

  componentDidMount() {
    this.viewModel.fetchData();
  }

  render() {
    return (
      <div className="page-content-wrapper">
        <SubHeader
          textoEsquerda="Parceria"
          textoCentral=""
          textoDireita=""
        />
        <div className="page-content">
          <div className="container">
            <div> <Head {...this.props} viewModel={this.viewModel} /> </div>
            <div> <List {...this.props} viewModel={this.viewModel} /></div>
          </div>
        </div>
      </div>
    );
  }
}
