import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";


import Acompanhamento from "../acompanhamento/index.js";
import CorridasAgendadas from "../agendadas/index.js";
import CorridasAndamento from "../andamento/index.js";
import Cadastro from "../cadastro/Cadastro.js";
import CorridasCanceladas from "../canceladas/index.js";
import CorridasConcluidas from "../concluidas/index.js";
import DetalhesCorrida from "../dadosCorrida/DetalhesCorrida";
import Departamentos from "../departamentos/index";
import FaturasV2 from "../faturas/index.js";
import Favoritos from "../favoritos/index.js";
import Loading from "../loading/Loading.js";
import Login from "../login/Login";
import MinhaConta from "../minhaConta/index.js";
import Pagamentos from "../pagamentos/index.js";
import Painel from "../painel/index.js";
import PreCadastro from "../precadastro/index.js";
import PreCadastroNew from "../precadastronew/index.js";
import VouchersAFaturarV2 from "../relatorios/AFaturar/index.js";
import VouchersCanceladosV2 from "../relatorios/Cancelados/index.js";
import CentroDeCustoV2 from "../relatorios/CentroDeCusto/index.js";
import EmpregadosV2 from "../relatorios/Empregados/index.js";
import VouchersFaturadosV2 from "../relatorios/Faturados/index.js";
import ResetSenha from "../resetarSenha/index.js";
import Solicitar from "../solicitacao/Solicitar.js";
import Solicitada from "../solicitada/index.js";
import Parceria from "../parceria/index.js";
import Usuarios from "../usuarios/index.js";
import CadastrarUsuarios from "../usuarios/view/CadastrarUsuarios.js";
import EditarUsuario from "../usuarios/view/EditarUsuario.js";
import {old, parceria} from '../../go-versao';

;
export default class Routes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var childProps = this.props.childProps;
    var isAuthenticated = this.props.isAuthenticated;
    var centralcores = this.props.centralcores;
    var loginUser = this.props.loginUser;

    if (!!isAuthenticated) {
      return (
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Solicitar {...props} childProps={childProps} />}
          />
          <Route
            exact
            path="/painel"
            render={(props) => <Painel {...props} childProps={childProps} />}
          />

         {/* ADICIONAR LAYOUT NOVO */}
          <Route
            exact
            path="/minha-conta"
            render={(props) => (
              <MinhaConta {...props} childProps={childProps} />
            )}
          />
          {/* ------------------------------- */}

          <Route
            exact
            path="/acompanhar/:id"
            render={(props) => <Solicitar {...props} childProps={childProps} />}
          />
          <Route
            exact
            path="/solicitar-corrida"
            render={(props) => <Solicitar {...props} childProps={childProps} />}
          />
          <Route
            exact
            path="/solicitar"
            render={(props) => <Solicitar {...props} childProps={childProps} />}
          />
          <Route
            exact
            path="/corrida-registrada"
            render={(props) => (
              <Solicitada {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/corrida-andamento"
            render={(props) => (
              <CorridasAndamento {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/corridas-agendadas"
            render={(props) => (
              <CorridasAgendadas {...props} childProps={childProps} />
            )}
          />
          {!!parceria &&
          <Route
            exact
            path="/parceria"
            render={(props) => (
              <Parceria {...props} childProps={childProps} />
            )}
          />}
          <Route
            exact
            path="/corridas-concluidas"
            render={(props) => (
              <CorridasConcluidas {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/corridas-canceladas"
            render={(props) => (
              <CorridasCanceladas {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/detalhes/:id"
            render={(props) => (
              <DetalhesCorrida {...props} childProps={childProps} />
            )}
          />
         
          <Route
            exact
            path="/faturas"
            render={(props) => <FaturasV2 {...props} childProps={childProps} />}
          />

          <Route
            exact
            path="/pagamentos"
            render={(props) => (
              <Pagamentos {...props} childProps={childProps} />
            )}
          />

          <Route
            exact
            path="/departamentos"
            render={(props) => (
              <Departamentos {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/usuarios"
            render={(props) => <Usuarios {...props} childProps={childProps} />}
          />
          <Route
            exact
            path="/editar-usuario"
            render={(props) => <EditarUsuario {...props} childProps={childProps} />}
          />
           <Route
            exact
            path="/cadastrar-usuario"
            render={(props) => <CadastrarUsuarios {...props} childProps={childProps} />}
          />

          <Route
            exact
            path="/voucher-faturar2"
            render={(props) => (
              <VouchersAFaturarV2 {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/voucher-faturados2"
            render={(props) => (
              <VouchersFaturadosV2 {...props} childProps={childProps} />
            )}
          />
          <Route
            exact
            path="/voucher-cancelados2"
            render={(props) => (
              <VouchersCanceladosV2 {...props} childProps={childProps} />
            )}
          />
            <Route
              exact
              path="/empregados2"
              render={(props) => (
                <EmpregadosV2 {...props} childProps={childProps} />
              )}
            />
            <Route
              exact
              path="/centro-de-custo2"
              render={(props) => (
                <CentroDeCustoV2 {...props} childProps={childProps} />
              )}
            />
          
          <Route
            exact
            path="/favoritos"
            render={(props) => <Favoritos {...props} childProps={childProps} />}
          />
          
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Login
                {...props}
                icone={centralcores.appicon}
                central={centralcores.id}
                childProps={childProps}
                loginUser={loginUser}
              />
            )}
          />
          <Route
            exact
            path="/login"
            render={(props) => (
              <Login
                {...props}
                icone={centralcores.appicon}
                central={centralcores.id}
                childProps={childProps}
                loginUser={loginUser}
              />
            )}
          />
          <Route
            exact
            path="/t/:id"
            render={(props) => (
              <Acompanhamento {...props} childProps={childProps} />
            )}
          />

          
          <Route
            exact
            path="/reset_password/:id"
            render={(props) => (
              <ResetSenha {...props} childProps={childProps} />
            )}
          />
          

          <Route
            exact
            path="/cadastro"
            render={(props) => (
              <Cadastro
                {...props}
                icone={centralcores.appicon}
                childProps={childProps}
                central={centralcores.id}
              />
            )}
          />

          {!old && (
            <Route
              exact
              path="/precadastro"
              render={(props) => (
                <PreCadastroNew {...props} icone={centralcores.appicon} />
              )}
            />
          )}

          {!!old && (
            <Route
              exact
              path="/precadastro"
              render={(props) => (
                <PreCadastro {...props} icone={centralcores.appicon} />
              )}
            />
          )}
          
          <Route
            path="*"
            render={(props) => (
              <Loading
                {...props}
                icone={centralcores.appicon}
                central={centralcores.id}
                childProps={childProps}
                loginUser={loginUser}
              />
            )}
          />
        </Switch>
      );
    }
  }
}
