import CoreModel from "../service/CoreModel";
import Service from "./Service";
import { old }  from '../../go-versao';

var jwtDecode = require("jwt-decode");

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.state = {
      appshowNovaSenha: false,
      userData: [],
      userDatausernome: null,
      userDatauseremail: null,
      isAuthenticated: false,
      selected: {},
      dataCentrais: [],
      dataEmpresas: [],
      empresa: {
        nome: "",
        cnpj: "",
        nomefantasia: "",
        endereco: "",
        telefone: "",
      },
      centralcores: {
        id: 3,
        razaosocial: "GO Soluçoes",
        aplicativocor: {
          terceary: "#ffffff",
          background: "#000000",
          foreground: "#ffffff",
        },
        appicon: null,
      },
      perfil: "",
      user: [],
      func: [],
      showCentral: false,
      showEmpresa: false,
      showUser: "none",
    };

    this.modalHideEmpresa = this.modalHideEmpresa.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.toggleRowEmpresa = this.toggleRowEmpresa.bind(this);
    this.setShowCentral = this.setShowCentral.bind(this);
    this.setShowEmpresa = this.setShowEmpresa.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.onchangeSenha = this.onchangeSenha.bind(this);
    this.onchangeSenhaConfirm = this.onchangeSenhaConfirm.bind(this);
    this.buttonConfirmSenha = this.buttonConfirmSenha.bind(this);
    this.modalHidenovasenha = this.modalHidenovasenha.bind(this);
    
  }

  childProps() {

    let cor1 =
    this.state.selected.id && this.state.isAuthenticated
      ? this.state.selected.aplicativocor.foreground
      : this.state.centralcores.aplicativocor.foreground;
  let cor2 =
    this.state.selected.id && this.state.isAuthenticated
      ? this.state.selected.aplicativocor.background
      : this.state.centralcores.aplicativocor.background;
  let cor3 =
    this.state.selected.id && this.state.isAuthenticated
      ? this.state.selected.aplicativocor.terceary
      : this.state.centralcores.aplicativocor.terceary;
  let cor4 =
    this.state.selected.id && this.state.isAuthenticated
      ? this.state.selected.aplicativocor.quaternary
      : this.state.centralcores.aplicativocor.quaternary;
  let cor5 =
    this.state.selected.id && this.state.isAuthenticated
      ? this.state.selected.aplicativocor.quintary
      : this.state.centralcores.aplicativocor.quintary;
      let cor6 =
        this.state.selected.id && this.state.isAuthenticated
          ? this.state.selected.aplicativocor.sextary
          : this.state.centralcores.aplicativocor.sextary;
  
      if (!cor1) {cor1 = "#FFFFFF"}
      if (!cor2) {cor2 = cor1}
      if (!cor3) {cor3 = cor2}
      if (!cor4) {cor4 = cor3}
      if (!cor5) {cor5 = cor4}
      if (!cor6) {cor6 = cor5}

    return {
      central: this.state.selected,
      centrais: this.state.dataCentrais,
      user: this.state.userData,
      func: this.state.func,
      favorito: null,
      empresaData: this.state.empresa,
      cores: this.state.selected.aplicativocor,
      useColors: {
        cor1: cor1,
        cor2: cor2,
        cor3: cor3,
        cor4: cor4,
        cor5: cor5,
        cor6: cor6
      }
    };

    

  }

  async getDadosCentralCores() {
    let domain = this.getCentralSubDominio();
    //console.log("getDadosCentralCores", domain);

    let central = await this.service.getCentralInfosCores(domain);
    console.log("getDadosCentralCores", central);
    if (!!central.id) {
      this.setState({ centralcores: central });
    }
  }

  buttonConfirmSenha() {
    if (this.state.senhaConfirm !== "" && this.state.senha !== "") {
      if (this.state.senhaConfirm === this.state.senha) {
        this.service
          .editSenhaUser(this.state.senha, this.state.userData.id)
          .then(() => {
            this.modalHidenovasenha();
            alert("Senha alterada com sucesso!");
          });
      } else {
        this.setState({
          alertDisplay: "alert-danger",
          alertText: "As senhas não conferem!",
        });
      }
    } else {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "Por favor preencha sua nova senha!",
      });
    }
  }
  modalHide() {
    this.setState({ showCentral: false });
  }

  modalHideEmpresa() {
    this.setState({ showEmpresa: false });
  }

  setShowCentral() {
    this.setState({ showCentral: true });
  }

  handleLogout() {
    this.service.eraseCookie("portal-token");
    this.service.eraseCookie("empresa-id");
    this.service.eraseCookie("central-id");

    this.setState({
      isAuthenticated: false,
      isAuthenticating: false,
      userData: [],
      appshowNovaSenha: false,
      userDatausernome: null,
      userDatauseremail: null,
    });

    this.navigateTo("/");
  }

  setColorsControler(root) {
    let cor1 =
      this.state.selected.id && this.state.isAuthenticated
        ? this.state.selected.aplicativocor.foreground
        : this.state.centralcores.aplicativocor.foreground;
    let cor2 =
      this.state.selected.id && this.state.isAuthenticated
        ? this.state.selected.aplicativocor.background
        : this.state.centralcores.aplicativocor.background;
    let cor3 =
      this.state.selected.id && this.state.isAuthenticated
        ? this.state.selected.aplicativocor.terceary
        : this.state.centralcores.aplicativocor.terceary;
    let cor4 =
      this.state.selected.id && this.state.isAuthenticated
        ? this.state.selected.aplicativocor.quaternary
        : this.state.centralcores.aplicativocor.quaternary;
    let cor5 =
      this.state.selected.id && this.state.isAuthenticated
        ? this.state.selected.aplicativocor.quintary
        : this.state.centralcores.aplicativocor.quintary;
        let cor6 =
          this.state.selected.id && this.state.isAuthenticated
            ? this.state.selected.aplicativocor.sextary
            : this.state.centralcores.aplicativocor.sextary;
    
        if (!cor1) {cor1 = "#FFFFFF"}
        if (!cor2) {cor2 = cor1}
        if (!cor3) {cor3 = cor2}
        if (!cor4) {cor4 = cor3}
        if (!cor5) {cor5 = cor4}
        if (!cor6) {cor6 = cor5}

    let corBaseRba = this.hexToRgbA(cor2);

    if (!!this.state.centralcores.svgbackground && this.state.centralcores.svgbackground !== "") {
      root.style.setProperty(
        "--svg",
        `url('${this.state.centralcores.svgbackground}')`
      );

      if (!!this.state.centralcores.svgportal && this.state.centralcores.svgportal !== "") {
        root.style.setProperty(
          "--polygon",
          this.state.centralcores.svgportal
        );
    } 

    } else if (!old) {
      root.style.setProperty(
        "--svg",
        `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg">\
        <g stroke-linecap="round" stroke="${corBaseRba}" fill="${corBaseRba}">\
        <rect id="svg_3" height="120" width="100%" x="0"/>\
        <ellipse ry="40" rx="70%" id="svg_1" cy="120" cx="50%"/>\
        <ellipse ry="40" rx="48%" id="svg_1" cy="125" cx="50%"/>\
        <ellipse ry="40" rx="45%" id="svg_2" cy="130" cx="50%"/>\
        <ellipse ry="40" rx="42%" id="svg_2" cy="135" cx="50%"/>\
        <ellipse ry="40" rx="39%" id="svg_4" cy="140" cx="50%"/>\
        <ellipse ry="40" rx="36%" id="svg_4" cy="145" cx="50%"/>\
        <ellipse ry="40" rx="33%" id="svg_5" cy="150" cx="50%"/>\
        <ellipse ry="40" rx="30%" id="svg_5" cy="155" cx="50%"/>\
        <ellipse ry="40" rx="27%" id="svg_6" cy="160" cx="50%"/>\
        <ellipse ry="40" rx="24%" id="svg_6" cy="165" cx="50%"/>\
        <ellipse ry="40" rx="21%" id="svg_6" cy="170" cx="50%"/>\
        <ellipse ry="40" rx="17%" id="svg_6" cy="175" cx="50%"/>\
        <ellipse ry="40" rx="14%" id="svg_6" cy="180" cx="50%"/>\
        <ellipse ry="40" rx="11%" id="svg_6" cy="185" cx="50%"/>\
        </g></svg>')`
      );
    } else if (!!old) {
      root.style.setProperty(
        "--svg",
        `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg">\
        <g stroke-linecap="round" stroke="${corBaseRba}" fill="${corBaseRba}">\
        <rect id="svg_3" height="30%" width="100%" x="0"/>\
        </g></svg>')`
      );
    }



    if (old) {
      root.style.setProperty("--nav-heade-size1", "60px");
      root.style.setProperty("--nav-heade-size2", "5px");
      root.style.setProperty("--round-size5", "3px");
      root.style.setProperty("--round-size7", "4px");
      root.style.setProperty("--round-size12", "5px");
    }

    root.style.setProperty("--cor-letra-icone-minha-conta", cor5 + "FF");
    root.style.setProperty("--barra-menu", "#EBE3E3");
    root.style.setProperty("--barra-menu-font", cor2 + "FF");
    root.style.setProperty("--barra-menu-hover", cor1 + "44");
    root.style.setProperty("--barra-menu-hover-font", cor2 + "FF");
    root.style.setProperty("--cor-letra-menu", cor2 + "FF");
    root.style.setProperty("--cor-icone-menu", cor2 + "FF");
    root.style.setProperty("--cor-letra-dropdown", cor2 + "FF");
    root.style.setProperty("--barra-dropdown", cor5 + "FF");
    root.style.setProperty("--barra-dropdown-font", cor5 + "FF");
    root.style.setProperty("--barra-dropdown-hover", cor5 + "44");
    root.style.setProperty("--cor-icone-dropdown", cor2 + "FF");
    root.style.setProperty("--barra-dropdown-hover-font", cor5 + "FF");
    root.style.setProperty("--cor-letra-grafico", cor2 + "FF");
    root.style.setProperty("--cor-icone-grafico", cor2 + "FF");
    root.style.setProperty("--minha-conta-background", cor2 + "FF");
    root.style.setProperty("--minha-conta-hover", cor2 + "44");
    root.style.setProperty("--nome", cor2 + "CC");
    root.style.setProperty("--cor-empresas", cor2 + "CC");
    root.style.setProperty("--cor-empresas", cor2 + "CC");
    root.style.setProperty("--cor-caminho", cor2 + "CC");
    root.style.setProperty("--background--menor", cor3 + "FF");
    root.style.setProperty("--background--geral", cor5 + "FF");
    root.style.setProperty("--cor-01", cor1 + "FF");
    root.style.setProperty("--cor-02", cor2 + "FF");
    root.style.setProperty("--cor-03", cor3 + "FF");
    root.style.setProperty("--cor-04", cor4 + "FF");
    root.style.setProperty("--cor-05", cor5 + "FF");
    root.style.setProperty("--cor-06", cor6 + "FF");
  }

  toggleUser() {
    var css = this.state.showUser === "none" ? "inherit" : "none";
    this.setState({ showUser: css });
  }

  onchangeSenha(e) {
    this.setState({ senha: e });
  }

  onchangeSenhaConfirm(e) {
    this.setState({ senhaConfirm: e });
  }

  modalHidenovasenha() {
    this.setState({ appshowNovaSenha: false });
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgb(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ")"
      );
    }
    throw new Error("Bad Hex");
  }

  async toggleRowEmpresa(empresa) {
    this.service.getCentrais().then((result) => {
      result.map((c, i) => {
        if (c.id === empresa.central) {
          this.setState({ selected: c });
        }
        return null;
      });
    });
    await this.setState({ empresa: empresa });
    await this.service.setCookie("empresa-preferida", empresa.id, 30);
    await this.service.setCookie("empresa-id", empresa.id, 1);
    await this.service.setCookie("empresa-parceria", empresa.parceria, "");
    await this.service.setCookie(
      "central-id",
      parseInt(empresa.central, 10),
      1
    );

    let data = this.state.userData;
    await this.service.getFuncById(data.id).then(async (result) => {
      if (result.length > 0) {
        data.funcdata = result[0];
        data.func_id = result[0].id;
        data.func_role = result[0].role;
        await this.setState({
          perfilId: result[0].role,
          perfil: result[0].voucher_roles_descricao,
          func: result[0],
          userData: data,
        });
        return;
      }
    });
  }

  setShowEmpresa() {
    this.setState({ showEmpresa: true });
  }

  toggleRow(central) {
    this.setState({ selected: central }, () => {
      this.service.setCookie("central-id", this.state.selected.id, 1);
    });
  }

  async loginUser(user) {
    let data;
    if (user.token) {
      data = jwtDecode(user.token);
      this.service.setCookie("portal-token", user.token, 1);
    } else {
      data = jwtDecode(user);
      this.service.setCookie("portal-token", user, 1);
    }
    let empresafav = this.service.getCookie("empresa-preferida");

    if (data.empresas.length > 0) {
      if (!empresafav) {
        empresafav = data.empresas[0];
      }

      if (!data.empresas.includes(Number(empresafav))) {
        empresafav = data.empresas[0];
      }
      //
      await this.service.setCookie("empresa-id", empresafav, 1);

      this.service.getEmpresa(empresafav).then((result) => {
        this.setState({ empresa: result });
        this.toggleRowEmpresa(result);
        this.service.setCookie("central-id", parseInt(result.central, 10), 1);
      });
    }

    this.service.getEmpresas().then((result) => {
      this.setState({ dataEmpresas: result });
    });

    this.service.getCentrais().then((result) => {
      this.setState({ dataCentrais: result });
      let central = this.service.getCookie("central-id");
      if (!central) {
        this.toggleRow(result[0]);
      } else {
        result.map((c, i) => {
          if (c.id === parseInt(central, 10)) {
            this.setState({ selected: c });
          }
          return null;
        });
      }
    });

    await this.service.getUserById(data.id).then((result) => {
      data.user = result;
    });

    await this.service.getFuncById(data.id).then(async (result) => {
      if (result.length > 0) {
        this.setState({
          perfilId: result[0].role,
          perfil: result[0].voucher_roles_descricao,
          func: result[0],
        });
        data.funcdata = result[0];
        data.func_id = result[0].id;
        data.func_role = result[0].role;
        return;
      }
    });

    await this.setState({
      isAuthenticated: true,
      isAuthenticating: false,
      userData: data,
      appshowNovaSenha: data.user.nova_senha ? true : false,
      userDatausernome: data.user.nome,
      userDatauseremail: data.user.email,
    });
  }
}
